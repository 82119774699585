import { Controller } from '@hotwired/stimulus';
import { computePosition, autoUpdate, flip, shift } from '@floating-ui/dom';
import { useHotkeys } from 'stimulus-use/hotkeys';

const CLASS_NAME_SHOW = '-is-active';

let submenu;
let dropdown;

export default class extends Controller {
  static targets = ['trigger', 'menu', 'input'];
  static values = {
    placement: {
      type: String,
      default: 'bottom-start'
    },
    strategy: {
      type: String,
      default: 'absolute'
    },
    submenu: {
      type: Boolean,
      default: false
    }
  };

  connect() {
    useHotkeys(this, {
      esc: [this.hide]
    });

    this.menuTarget.style.position = this.strategyValue;
    this.innerMenuEl = this.menuTarget.querySelector('.y-dropdown__menu-inner');
    this.setTransformOrigin(this.placementValue);
  }

  setTransformOrigin(placement) {
    const transformOriginValues = {
      'bottom-end': 'top right',
      'bottom-start': 'top left',
      'top-end': 'bottom right',
      'top-start': 'bottom left',
      bottom: 'top center',
      top: 'bottom center',
      'right-end': 'left top',
      'right-start': 'left bottom',
      'left-end': 'right top',
      'left-start': 'right bottom',
      default: 'top right' // default value if none of the above conditions are met
    };
    const transformOrigin =
      transformOriginValues[placement] || transformOriginValues.default;
    this.innerMenuEl.style.transformOrigin = transformOrigin;
  }

  calcPosition = async () => {
    this.popper = await computePosition(this.triggerTarget, this.menuTarget, {
      placement: this.placementValue,
      strategy: this.strategyValue,
      middleware: [flip(), shift({ padding: 60 })] // Padding 60px for the height of navbar.
    }).then(({ x, y, strategy }) => {
      Object.assign(this.menuTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
        position: strategy
      });
    });
  };

  handleOutsideClick = (e) => {
    if (!this.isShown()) return;
    if (e.target.closest('[data-controller="dropdown"]')) return;
    if (e.target.closest('.flatpickr-calendar')) return;
    this.hide();
  };

  toggle(event) {
    if (event) event.preventDefault();
    return this.isShown() ? this.hide() : this.show();
  }

  show() {
    if (this.submenuValue) {
      if (submenu) submenu.hide();
      submenu = this;
    } else {
      if (dropdown) dropdown.hide();
      dropdown = this;
    }

    this.triggerTarget.classList.add(CLASS_NAME_SHOW);
    this.menuTarget.classList.add(CLASS_NAME_SHOW);
    this.floatingInstance = autoUpdate(
      this.triggerTarget,
      this.menuTarget,
      this.calcPosition
    );

    // TODO: Causes a scroll glitch, might look into it later
    // if (this.hasInputTarget) {
    //   this.inputTarget.focus();
    // }
    this.dispatch('show');

    if (!this.submenuValue) {
      document.addEventListener('click', this.handleOutsideClick);
    }
  }

  hide = () => {
    if (!this.isShown()) return;
    this.triggerTarget.classList.remove(CLASS_NAME_SHOW);
    this.menuTarget.classList.remove(CLASS_NAME_SHOW);
    this.floatingInstance = undefined;

    if (!this.submenuValue) {
      document.removeEventListener('click', this.handleOutsideClick);
    }

    this.closeSubmenus();

    this.dispatch('hide');
  };

  isShown() {
    return (
      this.menuTarget && this.menuTarget.classList.contains(CLASS_NAME_SHOW)
    );
  }

  closeSubmenus = () => {
    const dropdowns = this.element.querySelectorAll(
      '[data-controller="dropdown"]'
    );

    dropdowns.forEach((el) => {
      const controller = this.application.getControllerForElementAndIdentifier(
        el,
        'dropdown'
      );
      if (controller) controller.hide();
    });
  };

  disconnect() {
    if (submenu) submenu = undefined;
    if (dropdown) dropdown = undefined;

    this.hide();
  }
}
